import React from "react";
import Seo from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import { Box, makeStyles, useMediaQuery, Typography } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapPin,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import ButtonBackToHome from "../../components/Button/ButtonBackToHome";
import GoogleMapReact from "google-map-react";

const useStyles = makeStyles(theme => ({
  boxContact: {
    [theme.breakpoints.between(766, 1000)]: {
      marginLeft: "70px",
    },
  },
}));

const InfrastructurePage = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");

  const LocationPin = ({ text }) => {
    return (
      <Box>
        <LocationOnIcon style={{ color: "red" }} />
        <Box width={200} color="white">
          {text}
        </Box>
      </Box>
    );
  };

  return (
    <Layout>
      <Seo title="Liên hệ" />
      <Box bgcolor="#f5f5f5">
        <Box
          width={breakpointsXs ? "90%" : "80%"}
          margin="auto"
          py={breakpointsXs ? 3 : 7}
        >
          <Box
            display={breakpointsXs ? undefined : "flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">
              <b>LIÊN HỆ VỚI CHÚNG TÔI</b>
            </Typography>
            <Box my={2}>
              <ButtonBackToHome />
            </Box>
          </Box>
          <Box mt={3} fontSize={18}>
            Chúng tôi luôn sẵn sàng lắng nghe, trợ giúp khách hàng bất cứ vấn đề
            nào bạn cần
          </Box>
        </Box>
      </Box>
      <Box
        width={breakpointsXs ? "90%" : "80%"}
        margin="auto"
        py={breakpointsXs ? 4 : 8}
      >
        <Box display={breakpointsXs ? undefined : "flex"}>
          <Box width={breakpointsXs ? "100%" : "45%"}>
            <Box fontSize={28} color="#308632" mb={4.5}>
              <b>Công ty trách nhiệm hữu hạn 2-9</b>
            </Box>
            <Box
              ml={2}
              display={breakpointsXs ? "flex" : undefined}
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              <Box maxWidth={370}>
                <Box display="flex" mb={3.5} fontSize={18}>
                  <Box
                    height={48}
                    lineHeight="48px"
                    borderRadius="100%"
                    bgcolor="#0d47a1"
                    textAlign="center"
                  >
                    <Box fontSize={25} width={48} color="white">
                      <FontAwesomeIcon icon={faMapPin} />
                    </Box>
                  </Box>
                  <Box ml={3}>
                    <b>Địa chỉ : </b>
                    <br />
                    <Box>
                      Tổ dân phố Ngô Gia Khảm, P.Châu Sơn, Phủ Lý, Hà Nam
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" mb={3.5} fontSize={18}>
                  <Box
                    width={48}
                    height={48}
                    lineHeight="48px"
                    borderRadius="100%"
                    bgcolor="#0084ff"
                    textAlign="center"
                  >
                    <Box fontSize={25} color="white">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Box>
                  </Box>
                  <Box ml={3}>
                    <Box mb={1.5}>
                      <b>Email : </b>
                      <br />
                      <Box>congty29hn@gmail.com</Box>
                    </Box>
                    <b>Website : </b>
                    <br />
                    <Box>congtytrachnhiemhuuhan2-9.com.vn</Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.boxContact}>
                <Box display="flex" mb={2} alignItems="center" fontSize={18}>
                  <Box
                    width={48}
                    height={48}
                    lineHeight="48px"
                    borderRadius="100%"
                    bgcolor="#308632"
                    textAlign="center"
                  >
                    <Box fontSize={25} color="white">
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    </Box>
                  </Box>
                  <Box ml={3} display="flex">
                    <b>Hotline : </b>
                    <Box>&nbsp;0226.3858.948</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width={breakpointsXs ? "100%" : "55%"}
            height={breakpointsXs ? "300px" : "392px"}
          >
            <GoogleMapReact
              defaultCenter={{ lat: 20.528302, lng: 105.9084533 }}
              defaultZoom={16}
            >
              <LocationPin
                lat={20.528302}
                lng={105.9084533}
                text="Công ty trách nhiệm hữu hạn 2-9, Ngô Gia Khảm, Cầu Châu Sơn, Hoàng Hạnh, Châu Sơn, Phủ Lý, Hà Nam"
              />
            </GoogleMapReact>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default InfrastructurePage;
